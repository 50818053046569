// Foundation JavaScript
// Documentation can be found at: http://foundation.zurb.com/docs
$(document).foundation();

$(document).ready(function(){
	// http://www.techerator.com/2010/11/how-to-make-a-css-background-slideshow-with-jquery/

 var imgArr = new Array( // relative paths of images
 	'images/banners/bow1.jpg',
 	'images/banners/violin1.jpg',
 	'images/banners/violin2.jpg',
 	'images/banners/vperspective.jpg',
 	'images/banners/vtop.jpg'
 	);
 
 var preloadArr = [];
 var i;
 
 /* preload images */
 for(i=0; i < imgArr.length; i++){
 	preloadArr[i] = new Image();
 	preloadArr[i].src = imgArr[i];
 }
 
 var currImg = 1;
 
 
 /* image rotator */
 function changeImg(){
 /*
 	$('#image-slider').animate({opacity: 0}, 1000, function(){
 		$(this).css('background','url(' + preloadArr[currImg++%preloadArr.length].src +') top center no-repeat');
 	}).animate({opacity: 1}, 1000);
 */
 $('#image-slider').css('background-image', 'url(' + preloadArr[currImg].src + ')');
 $('#image-slider').css('background-size', 'cover');
 currImg = currImg < preloadArr.length - 1 ? currImg + 1 : 1;
 }

 setInterval(changeImg, 6000);
 
});